const OurValue = () => {
  return (
    <div className="py-20 bg-[#fff]">
      <div className=" lg:ml-[16%] lg:flex lg:flex-row flex-col justify-between">
        {/* right side content */}
        <div className="lg:w-[34%] w-[100%] lg:pt-20 pt-8 px-[20px] py-[10px] lg:px-0 lg:py-0">
          <h6 className="text-[20px] text-[#C75C6F] font-bold font-dm lg:text-left text-center">
            Our Values
          </h6>
          <h1 className="lg:text-[55px] xl:text-[70px] 2xl:text-[84px] text-[40px] lg:text-left text-center text-[#292930] font-medium font-dm lg:leading-[82px] leading-[initial] py-5 tracking-tight">
            Realtionships are at the core of 4IL
          </h1>
          <p className="lg:text-[18px] text-[16px] text-[#999FAE] font-poppins font-normal leading-[27px] py-5 lg:text-left text-center">
            And not just the romantic kind. We can’t accomplish really hard
            things alone—so we make great relationships the foundation of our
            teamwork.
          </p>
          <p className="lg:text-[18px] text-[16px] text-[#999FAE] font-poppins font-normal leading-[27px] py-5 lg:text-left text-center">
            We believe these three core values are what it takes to build those
            great relationships.
          </p>
        </div>
        {/* left side image */}
        <div className="lg:w-[60%] w-[100%]">
          <img src={require("../../assets/img/ourvalue-img.jpg")} alt="" />
        </div>
      </div>
      {/* card  */}
      <div className="flex flex-wrap lg:flex-row flex-col justify-center xl:ml-[8%] xl:mr-0 md:mx-[4%] mx-[2%]   ">
        {/* box 1 */}
        <div className="xl:w-3/12 lg:w-4/12 bg-[#FFEDDC] lg:m-7 m-4 flex flex-col justify-between items-center pt-10 rounded-[24px]">
          <div className="lg:w-72 w-56 lg:h-72 h-56 bg-[#FAB8C4] mb-6 rounded-full flex justify-center ">
            <img
              src={require("../../assets/img/conversation.png")}
              alt=""
              className="lg:mt-16 mt-12 lg:h-[150px] h-[120px]"
            />
          </div>
          <div className="bg-[#fff] m-3 py-8 px-5 2xl:px-8 rounded-[24px] h-full lg:h-[258px] md:w-[97%] w-[94%] lg:w-[94%]">
            <h1 className="lg:text-[45px] text-[35px] text-[#DDDDE8] font-dm font-medium leading-[45px]">
              01
            </h1>
            <h3 className="lg:text-[32px] text-[24px] text-[#292930] font-dm font-medium ">
              Authenticity
            </h3>
            <p className="lg:text-[16px] text-[14px] text-[#999FAE] font-poppins font-normal leading-[24px] pt-3 pb-5">
              We share — never hide — our words, actions, and intentions.
            </p>
          </div>
        </div>
        {/* box 2 */}
        <div className="xl:w-3/12 lg:w-4/12 bg-[#DBDEFF] lg:m-7 m-4 flex flex-col justify-between items-center pt-10 rounded-[24px] ">
          <div className="lg:w-72 w-56 lg:h-72 h-56 bg-[#5956E9] mb-6 rounded-full flex justify-center  ">
            <img
              src={require("../../assets/img/target.png")}
              alt=""
              className="lg:mt-16 mt-14 lg:h-[150px] h-[120px]"
            />
          </div>
          <div className="bg-[#fff] m-3 py-8 px-5 2xl:px-8 rounded-[24px] lg:h-[258px] md:w-[97%] w-[94%] lg:w-[94%]">
            <h1 className="lg:text-[45px] text-[35px] text-[#DDDDE8] font-dm font-medium leading-[45px]">
              02
            </h1>
            <h3 className="lg:text-[32px] text-[24px] text-[#292930] font-dm font-medium ">
              Courage
            </h3>
            <p className="lg:text-[16px] text-[14px] text-[#999FAE] font-poppins font-normal leading-[24px]  pt-3 pb-5">
              Copy Breakthroughs require a willingness to take risks and embrace
              lofty goals and tough challenges.
            </p>
          </div>
        </div>
        {/* box 3 */}
        <div className="xl:w-3/12 lg:w-4/12 bg-[#DBF8FF] lg:m-7 m-4 flex flex-col justify-between items-center pt-10 rounded-[24px]">
          <div className="lg:w-72 w-56 lg:h-72 h-56 bg-[#90C8FC] mb-6 rounded-full flex justify-center  ">
            <img
              src={require("../../assets/img/phone.png")}
              alt=""
              className="lg:mt-16 mt-14 ml-2 lg:h-[150px] h-[120px]"
            />
          </div>
          <div className="bg-[#fff] m-3 py-8 px-5 2xl:px-8 rounded-[24px] lg:h-[258px] md:w-[97%] w-[94%] lg:w-[94%]">
            <h1 className="lg:text-[45px] text-[35px] text-[#DDDDE8] font-dm font-medium leading-[45px]">
              03
            </h1>
            <h3 className="lg:text-[32px] text-[24px] text-[#292930] font-dm font-medium ">
              Empathy
            </h3>
            <p className="lg:text-[16px] text-[14px] text-[#999FAE] font-poppins font-normal leading-[24px]  pt-3 pb-5">
              We’re all humans first. So we deeply consider the perspectives of
              others, listen openly, and speak with care.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurValue;
