import Banner from "../../component/homepage/banner";
import AboutUs from "../../component/homepage/aboutUs";
import OurValue from "../../component/homepage/ourValue";
import AppInfo from "../../component/homepage/appInfo";

const Homepage = () => {
  return (
    <>
      <Banner />
      <AboutUs />
      <OurValue />
      <AppInfo />
    </>
  );
};
export default Homepage;
