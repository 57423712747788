const AboutUs = () => {
  return (
    <div className="bg-[#27272E] pt-20 ">
      {/* about us content */}
      <h1 className="mx-[4%] md:mx-[6%] lg:mx-[8%] text-[#fff] lg:text-[56px] text-[35px] font-medium font-dm leading-[56px] lg:text-left text-center">
        Why 4IL?
      </h1>
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%]  flex flex-col lg:flex-row justify-between">
        <h3 className="lg:w-[45%] text-[#fff] lg:text-[24px] text-[18px] font-medium font-dm lg:leading-[34px] leading-[25px] py-3 lg:text-left text-center">
          21st CENTURY CUPID STUDIO REIMAGINING HOW TO HELP MORE PEOPLE FIND
          THEIR PERSON
        </h3>
        <div className="lg:w-[50%]  ">
          <p className="text-[#fff] lg:text-[18px] text-[15px] font-medium font-dm leading-[20px] py-5 lg:text-left text-center">
            We're a small team of curious humans who work on understanding what
            makes a 4IL makes a 4IL match successful, so that they can find your
            true soulmate. With relationship at the heart of our project, we
            identify what core values resonate and then bring the best people
            together to create something truly extraordinary
          </p>
          {/* <div className="flex lg:justify-start justify-center">
            <button className="lg:px-8 px-6 lg:py-4 py-3 border rounded-[40px] text-[#fff] lg:text-[16px] text-[14px] font-medium font-dm hover:text-[#27272E] hover:bg-[#fff]">
              Show detail — about us
            </button>
          </div> */}
        </div>
      </div>
      {/* video */}
      <div className="video_img lg:h-[900px] h-[600px] mx-[4%] md:mx-[6%] lg:mx-[8%] mt-20"></div>
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex justify-between py-10">
        <h6 className="text-[#fff] lg:text-[24px] text-[14px] font-medium font-dm leading-[34px] mr-1">
          showcase events 4IL
        </h6>
        <h6 className="text-[#fff] lg:text-[24px] text-[14px] font-medium font-dm leading-[34px] ml-1">
          showcase events 2023
        </h6>
      </div>
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex justify-center">
        <img
          src={require("../../assets/img/yellow.png")}
          alt=""
          className="h-[40px]"
        />
      </div>
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex justify-end">
        <img
          src={require("../../assets/img/violet.png")}
          alt=""
          className="mr-32 relative top-[40px] h-[70px]"
        />
      </div>
    </div>
  );
};
export default AboutUs;
