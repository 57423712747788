const Banner = () => {
  return (
    <div className="banner_img ">
      {/* left side image */}
      <div className="hidden lg:block">
        <img
          src={require("../../assets/img/banner-img1.png")}
          alt=""
          className="absolute right-0 lg:top-[100px] xl:top-[-3px] lg:h-[800px] xl:h-[1100px] 2xl:h-[1400px] homepage-img"
        />
      </div>
      <div className="flex justify-between mx-[4%] md:mx-[6%] lg:mx-[8%] lg:py-16 md:py-10 py-6">
        {/* left side */}
        <div className="flex pt-3 ">
          {/* <h4 className="text-[28px] font-poppins font-semibold px-6 ">LOGO</h4> */}
          <div>
            <img
              src={require("../../assets/img/logo-black.png")}
              alt=""
              className="lg:h-[60px] h-[50px]"
            />
          </div>
          {/* <ul className="px-16 pt-2  ">
            <li className="inline text-[16px] text-[#292930] font-poppins font-medium mx-7 pb-1 border-b-2 border-[#5956E9] cursor-pointer">
              Features
            </li>
            <li className="inline text-[16px] text-[#999FAE] font-poppins font-[medium] mx-7 pb-1 hover:text-[#292930] hover:border-b-2 hover:border-[#5956E9] cursor-pointer">
              Pricing
            </li>
            <li className="inline text-[16px] text-[#999FAE] font-poppins font-medium mx-7 pb-1 hover:text-[#292930] hover:border-b-2 hover:border-[#5956E9] cursor-pointer">
              Tours
            </li>
            <li className="inline text-[16px] text-[#999FAE] font-poppins font-medium mx-7 pb-1 hover:text-[#292930] hover:border-b-2 hover:border-[#5956E9] cursor-pointer">
              Explore
            </li>
          </ul> */}
        </div>
        {/* right side */}
        {/* <div className="flex relative ">
          <img
            src={require("../../assets/img/search.png")}
            alt=""
            className="h-6 mx-10 mt-5"
          />
          <button className="px-7 py-5 bg-[#FAB8C4] text-[#2522BA] font-medium font-poppins rounded-[20px]">
            Get Started
          </button>
        </div> */}
      </div>
      {/* main content */}
      <div className="mt-36 lg:pl-40 lg:pr-10 mx-[4%] md:mx-[5%] lg:mx-[8%] hidden lg:block">
        <h1 className="text-[96px] text-[#292930] font-bold leading-[96px] font-dm tracking-tight">
          Find Love <br />
          <span className="flex">
            Like <div className="w-20 h-2 mt-[75px] ml-2 bg-[#5956E9]"></div>
          </span>
          Never
          <br />{" "}
          <span className="flex">
            Before
            <div className="w-4 h-4 mt-[68px] ml-2 bg-[#5956E9] rounded-full"></div>
          </span>
        </h1>
        <p className="text-[18px] text-[#999FAE] font-normal font-poppins py-5">
          We want anyone looking for love <br />
          To be able to find it.
        </p>
      </div>

      {/* mobile */}
      <div className="mt-36 lg:pl-40 lg:pr-10 mx-[4%] md:mx-[5%] lg:mx-[8%]  lg:hidden">
        <h1 className="text-[76px] text-[#292930] font-bold leading-[80px] font-dm tracking-tight text-center ">
          Find Love
          <span className="flex justify-center">
            Like
            <div className="w-12 h-[4px] ml-2 mt-[60px] bg-[#5956E9]"></div>
          </span>
          Never
          <span className="flex justify-center">
            Before
            <div className="w-2 h-2  mt-[58px] ml-1 bg-[#5956E9] rounded-full"></div>
          </span>
        </h1>
        <p className="text-[15px] text-[#999FAE] font-normal font-poppins py-5 text-center">
          We want anyone looking for love <br />
          To be able to find it.
        </p>
      </div>
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] lg:flex lg:flex-row lg:justify-start flex flex-col justify-center lg:pl-40 py-4 ">
        <div className="flex justify-center">
          <img
            src={require("../../assets/img/app.png")}
            alt=""
            className="mt-2 lg:w-[240px] lg:h-[73px] w-[200px] md:h-[65px] h-[50px]  "
          />
        </div>
        <div className="flex justify-center">
          <img
            src={require("../../assets/img/google.jpg")}
            alt=""
            className="mx-2 mt-[9px] lg:w-[240px] lg:h-[72px] w-[200px] md:h-[65px] h-[50px] "
          />
        </div>
        <div className="flex justify-center">
          <div className="w-7 h-7 bg-[#5956E9] rounded-full mt-8 ml-3  "></div>
        </div>
      </div>
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex lg:justify-start justify-center lg:pl-40 lg:pt-40 lg:pb-10 py-10  ">
        <img
          src={require("../../assets/img/scroll.png")}
          alt=""
          className="h-8 cursor-pointer"
        />
        <h6 className="text-[16px] text-[#999FAE] font-normal px-5 pt-1 ">
          Scroll to explore
        </h6>
      </div>
      {/* bottom round img */}
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex justify-center">
        <img
          src={require("../../assets/img/circle.png")}
          alt=""
          className="relative lg:top-[80px] top-[45px] lg:left-[160px] lg:h-[160px] h-[100px]"
        />
      </div>
    </div>
  );
};
export default Banner;
