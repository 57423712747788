import { Route, Routes } from "react-router-dom";
// pages
import Homepage from "../container/homepage";

// layout
import Layout from "../shared";

const AllPagesRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Homepage />} />
      </Routes>
    </Layout>
  );
};
export default AllPagesRoutes;
