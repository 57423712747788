const AppInfo = () => {
  return (
    <div className="bg-[#fff] mx-[1%] md:mx-[3%] lg:mx-[8%] relative">
      <div className="absolute xl:pt-[85px] lg:pt-[65px] md:pt-[55px] sm:pt-[45px] pt-[25px] z-10 w-full flex flex-col items-center ">
        <h1 className="xl:text-[80px] lg:text-[60px] md:text-[45px] sm:text-[30px] text-[23px] xl:leading-[80px] lg:leading-[60px] md:leading-[40px] leading-[30px] font-dm font-medium text-[#fff] text-center">
          What are you waiting <br className="lg:block hidden" /> for?
        </h1>
        <h6
          className="lg:text-[18px] md:text-[16px] text-[14px] font-normal text-[#fff] font-poppins xl:pt-5 
        md:pt-4 sm:pt-2 pt-1 cursor-pointer "
        >
          Download the app now
        </h6>
      </div>
      <div>
        <img src={require("../../assets/img/app-image.png")} alt="" />
      </div>
    </div>
  );
};
export default AppInfo;
