const Footer = () => {
  return (
    <div className="py-10  mx-[4%] md:mx-[7%] lg:mx-[14%]">
      <div className="flex flex-wrap lg:flex-row justify-between lg:justify-center py-10">
        {/* box 1 */}
        {/* <div className="lg:w-3/12 w-full">
          <h4 className="lg:text-[28px] text-[24px] font-semibold text-[#292930] font-poppins leading-[28px] ">
            LOGO
          </h4>
        </div> */}
        {/* box 2 */}
        {/* <div className="lg:w-3/12 w-3/12 md:w-4/12 mobile-width">
          <ul>
            <li className="lg:text-[16px] text-[15px] font-normal text-[#999FAE] font-poppins  lg:pb-7 lg:pt-0 pt-5 pb-2 cursor-pointer">
              Index
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Mission
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Careers
            </li>
            <li className="lg:text-[16px] text-[14px]] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Press
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Blog
            </li>
            <li className="flex lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Contact
              <img
                src={require("../../src/assets/img/twitter.png")}
                alt=""
                className="h-[19px] px-1 "
              />
              <img
                src={require("../../src/assets/img/instagram.png")}
                alt=""
                className="h-[19px] px-1"
              />
            </li>
          </ul>
        </div> */}
        {/* box 3 */}
        {/* <div className="lg:w-3/12 w-3/12 md:w-4/12 mobile-width">
          <ul>
            <li className="lg:text-[16px] text-[15px] font-normal text-[#999FAE] font-poppins lg:pb-8 lg:pt-0 pt-5 pb-2 cursor-pointer">
              Resources
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Happy Marriage Tips
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              FAQ
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Trust & Safety
            </li>
          </ul>
        </div> */}
        {/* box 4 */}
        {/* <div className="lg:w-3/12 w-3/12 md:w-4/12 mobile-width">
          <ul>
            <li className="lg:text-[16px] text-[15px] font-normal text-[#999FAE] font-poppins lg:pb-8 lg:pt-0 pt-5 pb-2 cursor-pointer">
              Legal
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Security
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Terms
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Privacy
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Cookie Policy
            </li>
            <li className="lg:text-[16px] text-[14px] font-normal text-[#292930] font-poppins py-2 cursor-pointer">
              Cookie Settings
            </li>
          </ul>
        </div> */}
      </div>
      {/* footer 2 */}
      <div className=" pt-8 border-t-2 ">
        {/* <h4 className="lg:text-[28px] text-[24px] font-semibold text-[#292930] font-poppins leading-[28px] pt-2 pb-3 ">
          LOGO
        </h4> */}
        <div className="pt-3 pb-4">
          <img
            src={require("../../src/assets/img/logo-black.png")}
            alt=""
            className="lg:h-[45px] h-[40px]"
          />
        </div>
        <div className="lg:flex justify-between">
          <h3 className="text-[14px] text-[#999FAE] font-medium font-dm">
            © 2023, G&G INNOVATIONS INFORMATION TECHNOLOGY LLC
          </h3>

          <div
            className="flex pt-4 lg:pt-0"
            onClick={() =>
              // this changes the scrolling behavior to "smooth"
              window.scrollTo({ top: 0, behavior: "smooth" })
            }
          >
            <img
              src={require("../../src/assets/img/scroll.png")}
              alt=""
              className="h-[24px] mr-3 cursor-pointer"
            />
            <h6 className="text-[14px] text-[#999FAE] font-medium font-dm cursor-pointer">
              Back to top
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
